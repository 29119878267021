<template>
    <div class="chart-holder">
        <div class="l-padded datepicker">
            <DateRangeInput :value="customRange" @input="loadHistory" />
        </div>

        <div class="chart">
            <div v-if="isLoading" class="l-stack l-center l-padded">
                <Spinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <ApexCharts
                v-if="!isLoading"
                height="440px"
                :options="chartOptions"
                :series="series"
            />
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone'
import Spinner from 'vue-simple-spinner'
import ApexCharts from 'vue-apexcharts'

import { httpHelper, measurementHelper } from '@/utils'

import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'

const valueConverters = {
    accel_y: () => 1,
    fill_level: value => parseInt(value * 100),
    mass: value => (value / 1000).toFixed(2),
    temperature: value => value.toFixed(2),
}

export default {
    name: 'AssetChartsView',
    components: {
        ApexCharts,
        DateRangeInput,
        Spinner,
    },
    mixins: [ChartHelper],
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        dataType: {
            type: String,
            required: true,
        },
        assetType: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            series: [
                {
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    type: 'area',
                    stacked: false,
                    animations: {
                        enabled: false,
                    },
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true,
                    },
                    toolbar: {
                        autoSelected: 'zoom',
                    },
                    ...(this.dataType === 'accel_y' && {
                        type: 'scatter',
                    }),
                },
                stroke: {
                    curve: 'straight',
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 0,
                    style: 'full',
                    ...(this.dataType === 'accel_y' && {
                        size: 8,
                    }),
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        inverseColors: false,
                        opacityFrom: 0.7,
                        opacityTo: 0,
                    },
                    ...(this.dataType === 'accel_y' && {
                        type: 'solid',
                    }),
                },
                yaxis: {
                    title: {
                        text: this.$t('yaxis_' + this.dataType),
                    },
                    min: this.getYMinValue(),
                    max: this.getYMaxValue(),
                    ...(['bin', 'sbb-bin'].includes(this.assetType) &&
                        this.dataType === 'distance' && {
                            title: {
                                text: this.$t('yaxis_fillLevel'),
                            },
                            min: 0,
                            max: 100,
                        }),
                    ...(this.dataType === 'accel_y' && {
                        show: false,
                        tickAmount: 2,
                        min: 0,
                        max: 2,
                    }),
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false,
                    },
                },
                tooltip: {
                    shared: false,
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                    ...(this.dataType === 'accel_y' && {
                        custom: () => null,
                    }),
                },
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            isLoading: false,
        }
    },
    watch: {
        dataType(newVal) {
            this.chartOptions.yaxis.title.text = this.$t('yaxis_' + newVal)
            this.updateYAxis()
            this.loadHistory(this.customRange)
        },
    },
    methods: {
        updateYAxis() {
            this.chartOptions.yaxis.min = this.getYMinValue()
            this.chartOptions.yaxis.max = this.getYMaxValue()
        },
        getYMinValue() {
            switch (this.dataType) {
                case 'temperature':
                    return -25
                case 'battery':
                case 'battery_voltage':
                case 'co2':
                case 'distance':
                case 'fill_level':
                case 'humidity':
                case 'mass':
                case 'voc':
                case 'volume':
                    return 0
            }
        },
        getYMaxValue() {
            switch (this.dataType) {
                case 'battery':
                case 'fill_level':
                case 'humidity':
                case 'temperature':
                    return 100
            }
        },
        async loadHistory({ startDate, endDate }) {
            this.isLoading = true
            this.startDate = moment(startDate)
            this.endDate = moment(endDate)
            this.customRange = {
                startDate: this.startDate.toDate(),
                endDate: this.endDate.toDate(),
            }
            const results = await this.loadData()
            this.addHistoryDataFromResponse(results)
            this.isLoading = false
        },
        async loadData() {
            let results = []
            let historyUrl =
                'measurements/?' +
                `timestamp_min=${encodeURIComponent(this.startDate.format())}` +
                `&timestamp_max=${encodeURIComponent(this.endDate.format())}` +
                `&tracker=${this.id}` +
                '&fields=timestamp,sensor_data' +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

            while (historyUrl) {
                const { data } = await httpHelper.get(historyUrl)
                results = results.concat(data.results)
                historyUrl = data.next
            }

            return results
        },
        addHistoryDataFromResponse(results) {
            let converter = valueConverters[this.dataType] || (value => value)
            let name = this.$t(`router.${this.dataType}`)
            let data = results.filter(item =>
                Object.prototype.hasOwnProperty.call(
                    item.sensor_data,
                    this.dataType
                )
            )

            if (this.dataType === 'distance') {
                if (this.assetType === 'bin') {
                    converter = measurementHelper.convertToBinLevel
                    name = this.$t('yaxis_fillLevel')
                } else if (this.assetType === 'sbb-bin') {
                    converter = measurementHelper.convertToSbbBinLevel
                    name = this.$t('yaxis_fillLevel')
                }
            }

            data = data.map(item => [
                item.timestamp,
                converter(item.sensor_data[this.dataType]),
            ])

            this.series = [{ name, data }]
        },
    },
}
</script>

<i18n>
{
    "en": {
        "yaxis_battery": "Battery (%)",
        "yaxis_battery_voltage": "Battery voltage (V)",
        "yaxis_co2": "Carbon dioxide (ppm)",
        "yaxis_distance": "Distance (m)",
        "yaxis_fillLevel": "Fill level (%)",
        "yaxis_fill_level": "Fill level (%)",
        "yaxis_humidity": "Humidity (%)",
        "yaxis_lower_loop": "Lower loop",
        "yaxis_mass": "Mass (t)",
        "yaxis_potential": "Potential",
        "yaxis_tau": "Tau",
        "yaxis_temperature": "Temperature (°C)",
        "yaxis_upper_loop": "Upper loop",
        "yaxis_voc": "Volatile organic compounds (ppb)",
        "yaxis_volume": "Volume (m³)",
        "yaxis_water_level": "Water level"
    },
    "de": {
        "yaxis_battery": "Batterie (%)",
        "yaxis_battery_voltage": "Batteriespannung (V)",
        "yaxis_co2": "Kohlenstoffdioxid (ppm)",
        "yaxis_distance": "Distanz (m)",
        "yaxis_fillLevel": "Füllstand (%)",
        "yaxis_fill_level": "Füllstand (%)",
        "yaxis_humidity": "Luftfeuchtigkeit (%)",
        "yaxis_lower_loop": "Untere Schlaufe",
        "yaxis_mass": "Masse (t)",
        "yaxis_potential": "Potential",
        "yaxis_tau": "Tau",
        "yaxis_temperature": "Temperatur (°C)",
        "yaxis_upper_loop": "Obere Schlaufe",
        "yaxis_voc": "Flüchtige organische Verbindungen (ppb)",
        "yaxis_volume": "Volumen (m³)",
        "yaxis_water_level": "Wasserpegel"
    },
    "it": {
        "yaxis_battery": "Batteria (%)",
        "yaxis_battery_voltage": "Tensione della batteria (V)",
        "yaxis_co2": "Anidride carbonica (ppm)",
        "yaxis_distance": "Distanza (m)",
        "yaxis_fillLevel": "Riempimento (%)",
        "yaxis_fill_level": "Riempimento (%)",
        "yaxis_humidity": "Umidità (%)",
        "yaxis_lower_loop": "Loop inferiore",
        "yaxis_mass": "Massa (t)",
        "yaxis_potential": "Potential",
        "yaxis_tau": "Tau",
        "yaxis_temperature": "Temperatura (°C)",
        "yaxis_upper_loop": "Loop superiore",
        "yaxis_voc": "Composti organici volatili (ppb)",
        "yaxis_volume": "Volume (m³)",
        "yaxis_water_level": "Livello dell'acqua"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-holder {
    display: flex;
    @include respond-to('for-tablet-down') {
        display: block;
    }
}

.chart {
    flex-grow: 100;
    margin: 1rem 1rem 1rem 0;
    border-left: $style-border;
    @include respond-to('for-tablet-down') {
        border: none;
    }
}

input[type='checkbox'] {
    margin-left: 50px;
}

label {
    padding-left: 10px;
}

.datepicker {
    width: 20%;
    min-width: 350px;

    @include respond-to('for-tablet-down') {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        border-bottom: $style-border;
    }
}
</style>
